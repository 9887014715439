import { ReactElement, createContext, useCallback, useState } from 'react';
import { IStateConfiguratorForm } from 'assets/ts/types';

interface IAppContext {
    confForm: {
        state: IStateConfiguratorForm;
        setState: (newState: IStateConfiguratorForm) => void;
    };
}

const initAppContext = {
    confForm: {
        state: { isActive: false, isNew: true },
        setState: (newState: IStateConfiguratorForm) => {},
    },
};

const AppContext = createContext<IAppContext>(initAppContext);

const AppProvider = ({ children }: { children: ReactElement }) => {
    const [confFormState, setConfFormState] = useState<IStateConfiguratorForm>(initAppContext.confForm.state);

    const onSetConfFormState = useCallback((params: IStateConfiguratorForm) => {
        setConfFormState(params);
    }, []);

    return (
        <AppContext.Provider
            value={{
                confForm: { state: confFormState, setState: onSetConfFormState },
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;
export type { IAppContext };
export { AppProvider };
