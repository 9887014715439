import React from 'react';
import { SingleValueProps, components } from 'react-select';
import styled from '@emotion/styled';
import cx from 'classnames';
import { ONLY_FOR_PRESALE_TAG_TEXT } from 'modules/configurator-form/assets';
import { IConfigurationFormProduct, ISelectOption } from 'assets/ts/types';

const Wrapper = styled.div<{}>``;

const SingleValue = ({ children, ...props }: SingleValueProps<IConfigurationFormProduct & ISelectOption>) => {
    const { data: option } = props;
    const { name: optionName, nameHtml: optionNameHtml, price: optionPrice, noPrice, onlyForPresale } = option;
    const price = optionPrice ? optionPrice.toLocaleString() : 'Нет цены';

    return (
        <components.SingleValue {...props}>
            <Wrapper className="select__option-content">
                <div className={cx(['select__option-name', { '_no-price': noPrice }])}>
                    {optionNameHtml && !optionNameHtml.includes('<script') ? (
                        <span dangerouslySetInnerHTML={{ __html: optionNameHtml }} />
                    ) : (
                        <span>{optionName}</span>
                    )}
                </div>
                {onlyForPresale && <div className="select__option-tag _presale">{ONLY_FOR_PRESALE_TAG_TEXT}</div>}
                {!noPrice && (
                    <div className="select__option-price-wrapper">
                        &mdash;
                        <span className="select__option-price">
                            {price} {!!optionPrice && ' $'}
                        </span>
                    </div>
                )}
            </Wrapper>
        </components.SingleValue>
    );
};

export default SingleValue;
