import { IDataEducation, IDataEducationItem, IEducation, IEducationItem } from 'assets/ts/types';

const convertEducationItem = (item: IDataEducationItem) => {
    const { link_name, created_at, updated_at, ...rest } = item;
    return { ...rest, linkName: link_name, dateCreated: created_at, dateUpdated: updated_at } as IEducationItem;
};

const convertEducation = ({ links }: IDataEducation) =>
    ({
        links: (links ?? []).map(item => convertEducationItem(item)),
    } as IEducation);

export default convertEducation;
export { convertEducationItem };
