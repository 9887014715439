import React from 'react';
import { ValueContainerProps, components } from 'react-select';
import styled from '@emotion/styled';
import { IConfigurationFormProduct, ISelectOption } from 'assets/ts/types';

const Wrapper = styled.div<{}>`
    all: inherit;
    padding: 0;
`;

const ValueContainer = ({ children, ...props }: ValueContainerProps<IConfigurationFormProduct & ISelectOption>) => {
    const { isMulti, getValue } = props;

    return (
        <components.ValueContainer {...props}>
            <Wrapper title={!isMulti ? getValue()[0]?.name : ''}>{children}</Wrapper>
        </components.ValueContainer>
    );
};

export default ValueContainer;
