import { IDataFeedbackAdminList, IDataFeedbackAdminListItem, IFeedbackAdminList, IFeedbackAdminListItem } from 'assets/ts/types';

const convertAdminFeedbackItem = ({ created_at, updated_at, created_by_user, updated_by_user, ...rest }: IDataFeedbackAdminListItem) =>
    ({
        dateCreated: created_at,
        dateUpdated: updated_at,
        userCreated: created_by_user,
        userUpdated: updated_by_user,
        ...rest,
    } as IFeedbackAdminListItem);

const convertFeedbackAdminResponse = ({ next, prev, pages_total, count, feedback_list, ...rest }: IDataFeedbackAdminList) =>
    ({
        pagePrev: prev,
        pageNext: next,
        pagesTotal: pages_total,
        itemsTotal: count,
        feedbackList: feedback_list.map(i => convertAdminFeedbackItem(i)),
        ...rest,
    } as IFeedbackAdminList);

export default convertFeedbackAdminResponse;
export { convertAdminFeedbackItem };
