interface IProps {
    classNamePrefix?: string;
    optionValue: string;
    optionLabel?: string;
}

const CreatableOptionLabel = ({ classNamePrefix = 'select', optionValue, optionLabel }: IProps) => {
    return (
        <div className={`${classNamePrefix}__option-new`}>
            {optionLabel && <span className={`${classNamePrefix}__option-new-label`}>{optionLabel}</span>}
            <span className={`${classNamePrefix}__option-new-value`}>{optionValue}</span>
        </div>
    );
};

export default CreatableOptionLabel;
