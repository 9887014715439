import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { IRoute, IStore } from 'assets/ts/types';
import 'modules/breadcrumbs/index.scss';

const Breadcrumbs = () => {
    const { router } = useSelector((state: IStore) => state);
    const { routes } = router;
    const location = useLocation();
    const { pathname } = location;
    const [currentRoute, setCurrentRoute] = useState<IRoute>();

    useEffect(() => {
        if (pathname && routes && routes.length) {
            const findRoute = (pathname: string, parentPathname: string = '', routesList: IRoute[]) => {
                let result: IRoute | undefined;

                for (let i = 0; i < routesList.length; i++) {
                    const { path, children } = routesList[i];
                    const pathStr = parentPathname === '' ? path : `${parentPathname !== '/' ? parentPathname : ''}/${path}`;

                    if (pathStr === pathname || (path.indexOf(':') === 0 && pathname.indexOf(`${parentPathname}/`) === 0)) {
                        return routesList[i];
                    } else if (children?.length) {
                        result = findRoute(pathname, pathStr, children || []);
                        if (result) break;
                    }
                }

                return result;
            };

            const route = findRoute(pathname, '', routes);
            setCurrentRoute(route);
        }
    }, [pathname, routes]);

    return (
        <div className="breadcrumbs">
            {currentRoute && (
                <Link className="breadcrumbs__link" to={pathname}>
                    {currentRoute.title}
                </Link>
            )}
        </div>
    );
};

export default Breadcrumbs;
