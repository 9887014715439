import { IConfigurationRsUpload, IFetchRsUploadData, TConfigurationRsUploadEvent } from 'assets/ts/types';

const convertRsUploadData = ({ bill_id, conf_id, user_id, event_type, ...rest }: IFetchRsUploadData) => {
    const res: IConfigurationRsUpload = {
        billId: bill_id,
        confId: conf_id,
        eventType: event_type as TConfigurationRsUploadEvent,
        userId: user_id,
        ...rest,
    };
    return res;
};

export default convertRsUploadData;
