import { IDataSlot, ISlot } from 'assets/ts/types';

const convertSlots = (slots: IDataSlot[]) => {
    const slotsList: ISlot[] = [];

    slots.forEach((slot: IDataSlot) => {
        const { name } = slot;
        const [categoryName, index] = name.split(':');

        slotsList.push({
            name,
            categoryName,
            index: Number(index),
        });
    });

    return slotsList;
};

export default convertSlots;
