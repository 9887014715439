import { IDataUserRole, IUserRole } from 'assets/ts/types';

const convertRole = (role: IDataUserRole) => {
    const { id, code, name, is_external, is_tech_support } = role;
    return { roleId: id, roleCode: code, roleName: name, isExternal: is_external, isTechSupport: is_tech_support } as IUserRole;
};

const convertUserRoles = (roles: IDataUserRole[]) => roles.map(role => convertRole(role));

export default convertUserRoles;
export { convertRole };
