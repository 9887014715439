const PATH_AUTH = '/';
const PATH_AUTH_DIFF_USER = 'auth-different-user';
const PATH_REGISTRATION = 'register';
const PATH_RESTORE = 'reset-password';
const PATH_PROFILE = 'profile';
const PATH_CONFIGURATIONS_LIST = 'list';
const PATH_CONFIGURATIONS_LIST_ITEM = 'configuration';
const PATH_CONFIGURATION_HISTORY = 'history';
const PATH_ADMIN = 'admin';
const PATH_ADMIN_USERS = 'users';
const PATH_ADMIN_SETTINGS = 'settings';
const PATH_ADMIN_ASSOCIATIONS = 'associations';
const PATH_ADMIN_STORAGES = 'storages';
const PATH_ADMIN_PRICES_MONITORING = 'prices-monitoring';
const PATH_ADMIN_FEEDBACK = 'feedback';
const PATH_PRODUCTS = 'products';
const PATH_PROJECTS = 'projects';
const PATH_EDUCATION = 'education';
const PATH_NEWS = 'news';
const PATH_ANY = '*';

export {
    PATH_AUTH,
    PATH_AUTH_DIFF_USER,
    PATH_REGISTRATION,
    PATH_RESTORE,
    PATH_PROFILE,
    PATH_CONFIGURATIONS_LIST,
    PATH_CONFIGURATIONS_LIST_ITEM,
    PATH_CONFIGURATION_HISTORY,
    PATH_ADMIN,
    PATH_ADMIN_USERS,
    PATH_ADMIN_SETTINGS,
    PATH_ADMIN_ASSOCIATIONS,
    PATH_ADMIN_STORAGES,
    PATH_ADMIN_PRICES_MONITORING,
    PATH_ADMIN_FEEDBACK,
    PATH_PRODUCTS,
    PATH_PROJECTS,
    PATH_EDUCATION,
    PATH_NEWS,
    PATH_ANY,
};
