import { ICategoryProduct, IDataCategoryProduct, IDataProductsColumn, IProductsColumn } from 'assets/ts/types';

const columnsNames: { [name: string]: string } = {
    pricelist_id: 'articul',
    price: 'priceRs',
    configurator_price: 'priceCustom',
    use_configurator_price: 'priceCustomUsed',
};

const convertCategoryProductsData = ({
    products: productsList,
    columns: columnsList,
}: {
    products: IDataCategoryProduct[];
    columns: IDataProductsColumn[];
}) => {
    const products: ICategoryProduct[] = productsList.map((item: IDataCategoryProduct) => {
        const {
            id,
            name,
            naming,
            pricelist_id,
            additional_items,
            price,
            configurator_price,
            use_configurator_price,
            is_virtual,
            is_active,
            ...rest
        } = item;
        const product: ICategoryProduct = {
            id,
            name,
            naming,
            isVirtual: is_virtual ?? false,
            isActive: is_active ?? true,
            articul: pricelist_id,
            priceRs: price,
            priceCustom: configurator_price,
            priceCustomUsed: use_configurator_price,
            additionalItems: [...(additional_items || [])],
            attributes: { ...rest },
        };

        return product;
    });

    const columns: IProductsColumn[] = columnsList.map(col => {
        const { name, display_name, sort_index_in_list_tmc, ...rest } = col;
        const colName = columnsNames[name] ?? name;
        return {
            name: colName,
            title: display_name,
            className: name.replace(/__|_/g, '-'),
            sortIndex: sort_index_in_list_tmc,
            ...rest,
        } as IProductsColumn;
    });

    return { products, columns };
};

export default convertCategoryProductsData;
export { columnsNames };
