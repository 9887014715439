import { ICategoriesData } from 'assets/ts/types';
import { createSlice } from '@reduxjs/toolkit';

const initialState: ICategoriesData = {};

const categoriesReducer = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCategories(state, action) {
            return action.payload;
        },
    },
});

export const { setCategories } = categoriesReducer.actions;
export default categoriesReducer.reducer;
