import { IDataUserRs, IUserRs } from 'assets/ts/types';

const convertUsersRsData = (usersRs: IDataUserRs[]) => {
    const listUsersRs: IUserRs[] = usersRs.map((user: IDataUserRs) => {
        const { rs_id, first_name, last_name, patronymic_name, name } = user;

        return {
            rsId: rs_id,
            firstName: first_name,
            lastName: last_name,
            patronymic: patronymic_name,
            login: name,
        };
    });

    return listUsersRs;
};

export default convertUsersRsData;
