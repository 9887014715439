import React, { ReactElement } from 'react';
import LayoutPublic from 'modules/layout/LayoutPublic';

interface IProps {
    children: ReactElement;
}

const RoutePublic = ({ children }: IProps) => {
    return <LayoutPublic>{children}</LayoutPublic>;
};

export default RoutePublic;
