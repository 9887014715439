import React from 'react';
import { LINKS } from 'modules/footer/assets';
import ScrollButton from 'modules/scroll-button';
import 'modules/footer/index.scss';

interface IProps {
    showScrollButton?: boolean;
}

const Footer = ({ showScrollButton = false }: IProps) => {
    return (
        <div className="footer">
            <div className="footer__container">
                <div className="footer__body">
                    <div className="footer__links">
                        {LINKS.map(({ name, title, link, blank }) => (
                            <a className="footer__link" key={`footer-link-${name}`} href={link} {...(blank ? { target: '_blank' } : {})}>
                                {title}
                            </a>
                        ))}
                    </div>
                </div>
                {showScrollButton && <ScrollButton />}
            </div>
        </div>
    );
};

export default Footer;
