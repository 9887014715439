import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, useLocation } from 'react-router-dom';
import Actions from 'api/actions';
import AppContext from 'store/context/AppContext';
import ModalContext from 'store/context/ModalContext';
import checkStorage from 'store/helpers/check-storage';
import { clearStorage, setAppsAvailable, setCurrentApp, setUserSettings } from 'store/reducers/app';
import setRoute from 'router/setRoute';
import setRoutes from 'router/setRoutes';
import Modal from 'modules/modal';
import { IApp, IAppUserSettings, IAppsList, IStore } from 'assets/ts/types';
import convertData from 'assets/ts/helpers/convert-data';
import fetchNotifications from 'assets/ts/helpers/fetch-notifications';
import getError from 'assets/ts/helpers/get-error';
import { ACTION_ID_USERS_CHECK_TOKEN, ACTION_ID_USERS_FETCH_APPS, ACTION_ID_USER_SETTINGS_FETCH, ROUND_DAY_MS } from 'assets/ts/constants';

const isDev = process.env.NODE_ENV === 'development';

const App = () => {
    const actions = new Actions();
    const dispatch = useDispatch();
    const { state: locationState } = useLocation();
    const { confForm } = useContext(AppContext);
    const { closeModal } = useContext(ModalContext);
    const { app, user, router } = useSelector((state: IStore) => state);

    const { token } = user;
    const { routes } = router;
    const { appsAvailable, currentApp } = app;
    const { showAssociationsPage } = appsAvailable.find((app: IApp) => app.value === currentApp) || {};

    const [error, setError] = useState<string>('');

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;

        const setSessionTimeout = () => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                dispatch(clearStorage());
            }, ROUND_DAY_MS);
        };

        closeModal();
        confForm.setState({ isActive: false, isNew: true });
        window.addEventListener('click', setSessionTimeout);

        return () => {
            clearTimeout(timeout);
            window.removeEventListener('click', setSessionTimeout);
        };
    }, []);

    useEffect(() => {
        let isTokenActive = false;
        let errorTimeout: ReturnType<typeof setTimeout>;

        const getApps = async () => {
            try {
                const result = await actions.fetchAppsAvailable();
                const { detail } = result;

                if (detail) {
                    throw new Error(getError({ detail }));
                }

                const list: IAppsList = convertData('apps-list', result);

                dispatch(setAppsAvailable(list));
                if (!list.find(item => item.value === app.currentApp)) {
                    dispatch(setCurrentApp(list[0].value));
                }
            } catch (error) {
                const errorStr = (error as Error).message;
                errorStr && console.error(errorStr);
            }
        };

        const getUserSettings = async () => {
            try {
                const result = await actions.fetchUserSettings();
                const { detail } = result;

                if (detail) {
                    throw new Error(getError({ detail }));
                }

                const userSettings: IAppUserSettings = convertData('user-settings', result);
                dispatch(setUserSettings(userSettings));
            } catch (error) {}
        };

        const checkToken = async () => {
            try {
                const result = await actions.checkToken(token);
                const { error_code } = result;
                isTokenActive = error_code === 0;

                if (isTokenActive) {
                    await getApps();
                    // await getUserSettings();
                    await fetchNotifications();
                }
                isDev && (await setRoutes(isTokenActive && checkStorage('user', user), showAssociationsPage, locationState));
            } catch (error) {
                setError((error as Error).message);
                errorTimeout = setTimeout(() => {
                    setError('');
                }, 10000);
            } finally {
                !isDev && (await setRoutes(isTokenActive && checkStorage('user', user), showAssociationsPage, locationState));
            }
        };

        if (token) {
            checkToken();
        } else {
            isTokenActive = false;
            setRoutes(false, showAssociationsPage, locationState);
        }

        return () => {
            clearTimeout(errorTimeout);
            actions.abort(ACTION_ID_USERS_CHECK_TOKEN);
            actions.abort(ACTION_ID_USERS_FETCH_APPS);
            actions.abort(ACTION_ID_USER_SETTINGS_FETCH);
        };
    }, [token, locationState, currentApp, showAssociationsPage]);

    return (
        <div className="app" id="app">
            {routes?.length ? (
                <>
                    {error && <div className="alert alert-danger app__alert">{error}</div>}
                    <Routes>{routes.map(route => setRoute(route))}</Routes>
                </>
            ) : (
                ''
            )}
            <Modal />
        </div>
    );
};

export default App;
