import { store } from 'store';
import { setCategories } from 'store/reducers/categories';
import { setRoutes as setRoutesReducer } from 'store/reducers/router';
import { ICategoriesData, IRoute } from 'assets/ts/types';

const { dispatch } = store;

const updateCategories = (categories: ICategoriesData) => {
    dispatch(setCategories(categories));
};

const updateRoutes = (routes: IRoute[]) => {
    dispatch(setRoutesReducer(routes));
};

export { updateRoutes, updateCategories };
