import { IConfigurationPricesError, IDataConfigurationPrice } from 'assets/ts/types';

const convertConfigurationPriceData = ({ prices, errors }: IDataConfigurationPrice) => {
    let priceErrors: IConfigurationPricesError[] | undefined = errors?.map(error => {
        const { id, pricelist_id, category_name, ...rest } = error;
        return {
            productId: id,
            productArticul: pricelist_id,
            productCategoryTitle: category_name,
            ...rest,
        };
    });

    return { prices, errors: priceErrors };
};

export default convertConfigurationPriceData;
