import { IAppsList, IDataAppsList } from 'assets/ts/types';

const convertAppsList = (data: IDataAppsList) => {
    const list: IAppsList = data.map(item => {
        const { value, show_associations_page, ...rest } = item;
        return { ...rest, value: value.toLowerCase(), showAssociationsPage: show_associations_page ?? false };
    });
    return list;
};

export default convertAppsList;
