import { IStateProducts } from 'assets/ts/types';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IStateProducts = {
    productsAll: [],
    elementId: null,
};

const productsReducer = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProductsList(state, action) {
            return { ...state, productsAll: action.payload };
        },
        setElementId(state, action) {
            return { ...state, elementId: action.payload };
        },
    },
});

export const { setProductsList, setElementId } = productsReducer.actions;
export default productsReducer.reducer;
