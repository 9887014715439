import {
    IDataNewsAdminList,
    IDataNewsAdminListItem,
    IDataNewsAdminListItemRole,
    INewsAdminList,
    INewsAdminListItem,
    INewsAdminListItemRole,
} from 'assets/ts/types';

const convertAdminNewsItemRole = (role: IDataNewsAdminListItemRole) => ({ roleId: role.role_id, roleName: role.role_name } as INewsAdminListItemRole);

const convertAdminNewsItem = (item: IDataNewsAdminListItem) => {
    const { created_at, updated_at, roles, ...rest } = item;
    return {
        dateCreated: created_at,
        dateUpdated: updated_at,
        roles: roles.map(i => convertAdminNewsItemRole(i)),
        ...rest,
    } as INewsAdminListItem;
};

const convertNewsAdminResponse = ({ next, prev, pages_total, count, news, ...rest }: IDataNewsAdminList) =>
    ({
        pagePrev: prev,
        pageNext: next,
        pagesTotal: pages_total,
        itemsTotal: count,
        news: news.map(i => convertAdminNewsItem(i)),
        ...rest,
    } as INewsAdminList);

export default convertNewsAdminResponse;
export { convertAdminNewsItem, convertAdminNewsItemRole };
