import { IDataStorage, IStorage } from 'assets/ts/types';

const convertStorages = (storages: IDataStorage[]) => {
    return storages.map(s => {
        const { is_select, store_uid, store_id, ...rest } = s;
        return {
            id: store_id,
            uid: store_uid,
            selected: is_select,
            ...rest,
        } as IStorage;
    });
};

export default convertStorages;
