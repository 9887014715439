import React, { ComponentProps } from 'react';
import styled from '@emotion/styled';
import { INPUT_NUMBER_RESTRICTED_CHARS } from 'assets/ts/constants';

interface InputWrapperProps extends ComponentProps<'input'> {
    inputWrapperClassName?: string;
}

const InputWrapper = styled.div`
    border: 1px solid #ececec;
    border-radius: 4px;
    height: 32px;
    padding: 6px 11px;
    input {
        border: 0;
        outline: 0;
        width: 32px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type='number'] {
            -moz-appearance: textfield;
        }
    }
`;

function InputNumber(props: InputWrapperProps) {
    const { inputWrapperClassName, ...rest } = props;
    return (
        <InputWrapper className={inputWrapperClassName}>
            <input
                type="number"
                onKeyDown={e => INPUT_NUMBER_RESTRICTED_CHARS.includes(e.key.toLowerCase()) && e.preventDefault()}
                onWheel={e => (e.target as HTMLInputElement).blur()}
                {...rest}
            />
        </InputWrapper>
    );
}

export default InputNumber;
