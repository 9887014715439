import Button from 'components/button';
import 'modules/scroll-button/index.scss';

const ScrollButton = () => {
    return (
        <Button type="button" styleType="primary" className="scroll-button" onClick={() => window.scrollTo(0, 0)} tip="К началу страницы">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-short" viewBox="0 0 16 16">
                <path d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
            </svg>
        </Button>
    );
};

export default ScrollButton;
