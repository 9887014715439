import { ReactElement } from 'react';
import Footer from 'modules/footer';

interface IProps {
    children: ReactElement;
}

const LayoutPublic = ({ children }: IProps) => {
    return (
        <div className="layout-public">
            <div className="layout-public__container">
                <div className="layout-public__body">{children}</div>
                <Footer />
            </div>
        </div>
    );
};

export default LayoutPublic;
