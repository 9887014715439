import { IDataProject, IDataProjectsResponse, IProject } from 'assets/ts/types';

const convertProjects = ({ data: projects, pages_total, prev, next, count, ...rest }: IDataProjectsResponse) => {
    const listProjects: IProject[] = projects.map((project: IDataProject) => {
        const { user_id, ...rest } = project;
        return { ...rest, userId: project.user_id };
    });

    return {
        projects: listProjects,
        projectsTotal: count,
        pagesTotal: pages_total,
        pagePrev: prev,
        pageNext: next,
        ...rest,
    };
};

export default convertProjects;
