import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import Layout from 'modules/layout';
import { IStore } from 'assets/ts/types';

interface IProps {
    redirectTo: string;
    children: ReactElement;
}

const RoutePrivate = ({ redirectTo, children }: IProps) => {
    const { user } = useSelector((state: IStore) => state);
    const location = useLocation();
    if (!user.token) {
        return <Navigate state={{ from: location }} to={redirectTo} replace />;
    }
    return <Layout>{children}</Layout>;
};

export default RoutePrivate;
