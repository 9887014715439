import { IStateRouter } from 'assets/ts/types';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IStateRouter = {
    routes: [],
};

const routerReducer = createSlice({
    name: 'router',
    initialState,
    reducers: {
        setRoutes(state, action) {
            return { ...state, routes: action.payload };
        },
    },
});

export const { setRoutes } = routerReducer.actions;
export default routerReducer.reducer;
