import { IDataProductAssociations, IProductAssociation, IProductAssociations } from 'assets/ts/types';

const convertProductAssociations = (data: IDataProductAssociations) => {
    const associations: IProductAssociations = {};

    Object.entries(data).forEach(([categoryName, items]) => {
        associations[categoryName] = items.map(association => {
            const { is_association, pricelist_id, comment, ...rest } = association;
            return {
                checked: is_association,
                articul: pricelist_id,
                comment: comment ?? '',
                ...rest,
            } as IProductAssociation;
        });
    });

    return associations;
};

export default convertProductAssociations;
