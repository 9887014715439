const LINKS: {
    name: string;
    title: string;
    link: string;
    blank: boolean;
}[] = [
    {
        name: 'cookies',
        title: 'Использование cookies',
        link: 'https://graviton.ru/cookies-policy/',
        blank: true,
    },
    {
        name: 'policy',
        title: 'Политика конфиденциальности',
        link: 'https://graviton.ru/policy/',
        blank: true,
    },
    {
        name: 'agreement',
        title: 'Пользовательское соглашение',
        link: 'https://graviton.ru/agreement/',
        blank: true,
    },
];

export { LINKS };
