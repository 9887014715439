import { ICategoriesData, ICategoryItem, IDataCategoriesData, IDataCategoriesResponse } from 'assets/ts/types';

const convertCategoriesData = ({ categories }: IDataCategoriesResponse) => {
    const listCategoriesData: ICategoriesData = {};

    categories.forEach((cat: IDataCategoriesData) => {
        const { name, display_name, show_excel, is_virtual } = cat;

        listCategoriesData[name] = {
            title: display_name,
            showExcel: show_excel,
            isVirtual: is_virtual,
        };
    });

    return listCategoriesData;
};

const convertCategoriesForFilter = ({ categories }: IDataCategoriesResponse) =>
    categories.map(
        category =>
            ({
                name: category.name,
                title: category.display_name,
            } as ICategoryItem)
    );

export default convertCategoriesData;
export { convertCategoriesForFilter };
