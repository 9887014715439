import { IConfiguration, IConfigurationsCopy, IConfigurationsList, IDataConfigurationsCopy, IDataConfigurationsResponse } from 'assets/ts/types';
import { convertColumnsSettings } from 'assets/ts/helpers/convert-data/user-settings';

const convertConfigurationsCopy = ({ copied_configuration_id }: IDataConfigurationsCopy) =>
    ({ copiedConfigurationId: copied_configuration_id } as IConfigurationsCopy);

const convertConfigurations = ({
    configurations,
    page,
    pages_total,
    count,
    prev,
    next,
    show_erp_bill,
    available_columns,
}: IDataConfigurationsResponse) => {
    const list: IConfiguration[] = configurations.map(conf => {
        const {
            user,
            tax_id,
            count,
            client_name,
            partner_name,
            doc_sheet_name,
            erp_bill_status,
            created_at,
            erp_bill_number,
            is_draft,
            is_virtual,
            type_config_virtual,
            app_id,
            pending_event,
            show_details,
            tkp_id,
            finished_product_article,
            ...rest
        } = conf;
        const { last_name, first_name, patronymic_name, name, ...restUser } = user;

        return {
            user: {
                lastName: last_name,
                firstName: first_name,
                patronymic: patronymic_name,
                login: name,
                ...restUser,
            },
            taxId: tax_id,
            amount: count,
            clientName: client_name,
            partnerName: partner_name,
            sheetName: doc_sheet_name,
            ERPUploadStatus: erp_bill_status,
            creationDate: created_at,
            billNumber: erp_bill_number,
            isDraft: is_draft,
            appId: app_id,
            pending: pending_event,
            isVirtual: is_virtual,
            typeConfigVirtual: type_config_virtual === 'C' ? null : type_config_virtual === 'V' ? 'В' : 'К',
            showDetails: show_details ?? false,
            tkpId: tkp_id,
            finishedProductArticle: finished_product_article,
            ...rest,
        } as IConfiguration;
    });

    return {
        configurations: list,
        configurationsTotal: count,
        page,
        pagePrev: prev,
        pageNext: next,
        pagesTotal: pages_total,
        showERP: show_erp_bill,
        availableColumns: convertColumnsSettings(available_columns),
    } as IConfigurationsList;
};

export default convertConfigurations;
export { convertConfigurationsCopy };
